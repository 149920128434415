.container {
  --overlap: 65lvh; /** Changes on desktop */
  --homepage-header-height: 72px; /* assumes this is only used on homepage */

  --stem-clip-progress-start: 50%;
  --stem-clip-progress-end: 100%;

  --leg-clip-progress-start: 100%;
  --leg-clip-progress-end: 0%;

  --cross-clip-progress-start: 0%;
  --cross-clip-progress-end: 100%;

  --loop-clip-progress-start: 0;
  --loop-clip-progress-end: 283; /* magic number */

  --animation-delay-initial: 1s; /* base delay added to all animations */
  --animation-delay-double-crosses: 1s;
  --animation-delay-slide-crosses: 1s;
  --animation-delay-loops: 1s;
  --animation-delay-legs: 1.5s;

  display: grid;
  align-items: start;
  justify-items: center;
  margin-bottom: calc(-1 * var(--overlap));
  padding-inline: var(--container-padding-inline);
  width: 100%;
  height: calc(100lvh + var(--overlap) - var(--header-height));

  /** ipad-mini and bigger */
  @media (min-width: 768px) {
    --overlap: 50lvh;

    align-items: flex-end;
    height: calc(100lvh + var(--overlap) - var(--header-height));
  }

  & svg {
    display: block;
    width: 100%;
  }

  /* Ensure elements are transformed relative to the shapes, not canvas */
  & svg * {
    transform-box: fill-box;
  }

  /*
   * Note on browser compatibility:
   * Originally we had clip paths inside the SVG, we were goign to animate them for the reveal animation.
   * Transforming clipPaths is buggy in Safari and Firefox.
   * Chrome is forgiving, transforms the clipPath, shapes, groups.
   * Safari likes to transform the shape (Rect) not the ClipPath itself.
   * Firefox doesn't respect transform-origin on SVG ClipPaths, so animations were wrong.
   * Therefore, we have a mix of transforms, clip-paths, and scales.
   */

  /* stop aimation from resetting */
  & svg :global(#main-group),
  & svg :global(#a-left),
  & svg :global(#a-right),
  & svg :global(#t),
  & svg :global(#n),
  & svg :global(#group-e2),
  & svg :global(#mask-3),
  & svg :global(#mask-2),
  & svg :global(#mask),
  & svg :global(#r2-leg),
  & svg :global(#r1-leg) {
    animation-fill-mode: forwards;
  }

  /* Main */
  /* This masks the entire mirror logo, to stop overlap artifacts. With JS it starts at 50% */
  & svg :global(#main-group) {
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% var(--stem-clip-progress-start),
      0% var(--stem-clip-progress-start)
    );
    animation-duration: 1s;
    animation-delay: var(--animation-delay-initial);
    animation-name: logo;
  }

  /* A Cross left/Right + T Cross */
  & svg :global(#a-left),
  & svg :global(#a-right),
  & svg :global(#t) {
    transform: scaleX(var(--cross-clip-progress-start));
    animation-duration: 1s;
    animation-delay: calc(var(--animation-delay-initial) + var(--animation-delay-double-crosses));
    animation-name: double-crosses;
  }

  /* A Cross left */
  /* ScaleX on black shape */
  & svg :global(#a-left) {
    transform-origin: left center;
  }

  /* A Cross Right */
  & svg :global(#a-right) {
    transform-origin: right center;
  }

  /* T Cross */
  & svg :global(#t) {
    transform-origin: center center;
  }

  /* N Cross + E Cross */
  & svg :global(#n),
  & svg :global(#group-e2) {
    transform-origin: left center;
    clip-path: polygon(
      0% 0%,
      var(--cross-clip-progress-start) 0%,
      var(--cross-clip-progress-start) 100%,
      0% 100%
    );
    animation-duration: 1s;
    animation-delay: calc(var(--animation-delay-initial) + var(--animation-delay-slide-crosses));
    animation-name: slide-crosses;
  }

  /* R Leg */
  & svg :global(#r2-leg),
  & svg :global(#r1-leg) {
    clip-path: polygon(
      0% var(--leg-clip-progress-start),
      100% var(--leg-clip-progress-start),
      100% 100%,
      0% 100%
    );
    animation-duration: 0.6s;
    animation-delay: calc(var(--animation-delay-initial) + var(--animation-delay-legs));
    animation-name: legs;
  }

  /* P R loops */
  /**
   * Use a stroke trick to draw the line following a path.
   * The path is a mask in the SVG.
   * https://www.cassie.codes/posts/creating-my-logo-animation/
   */
  & svg :global(#mask-3),
  & svg :global(#mask-2),
  & svg :global(#mask) {
    stroke-dasharray: var(--loop-clip-progress-end);
    stroke-dashoffset: var(--loop-clip-progress-start); /* value between 0-{stroke-dasharray} */
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: calc(var(--animation-delay-initial) + var(--animation-delay-loops));
    animation-name: loops;
  }
}

.inner {
  display: flex;
  position: sticky;
  top: var(--homepage-header-height);
  margin-top: calc(-1 * var(--header-height));
  width: 100%;
  height: calc(100lvh - var(--header-height));

  @media (min-width: 768px) {
    display: initial;
    top: initial;
    bottom: var(
      --container-padding-inline
    ); /* Sticks, but only for 50lvh - THEN scroll away normally. */
    margin-top: initial;
    height: initial;
  }
}

@keyframes logo {
  0% {
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% var(--stem-clip-progress-start),
      0% var(--stem-clip-progress-start)
    );
  }
  100% {
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% var(--stem-clip-progress-end),
      0% var(--stem-clip-progress-end)
    );
  }
}

@keyframes double-crosses {
  0% {
    transform: scaleX(var(--cross-clip-progress-start));
  }
  100% {
    transform: scaleX(var(--cross-clip-progress-end));
  }
}

@keyframes slide-crosses {
  0% {
    clip-path: polygon(
      0% 0%,
      var(--cross-clip-progress-start) 0%,
      var(--cross-clip-progress-start) 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      0% 0%,
      var(--cross-clip-progress-end) 0%,
      var(--cross-clip-progress-end) 100%,
      0% 100%
    );
  }
}

@keyframes loops {
  0% {
    stroke-dashoffset: var(--loop-clip-progress-start);
  }
  100% {
    stroke-dashoffset: var(--loop-clip-progress-end);
  }
}

@keyframes legs {
  0% {
    clip-path: polygon(
      0% var(--leg-clip-progress-start),
      100% var(--leg-clip-progress-start),
      100% 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      0% var(--leg-clip-progress-end),
      100% var(--leg-clip-progress-end),
      100% 100%,
      0% 100%
    );
  }
}
